
import {
  defineComponent, reactive, watch,
} from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import api from '@/api';
import { Mall } from '@/types/Markets';
import debounce from 'lodash.debounce';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    data: {
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const currentData = reactive({} as Mall);
    const modalMallState = reactive({
      loading: false,
      async submit() {
        modalMallState.loading = true;
        try {
          await api.malls.updateMall(currentData as Mall);
          // alert('Успешно обновлено');
          emit('close');
        } catch (e: unknown) {
          alert((e as Record<string, unknown>)?.message || 'Неизвестная ошибка на сервере');
        } finally {
          modalMallState.loading = false;
        }
      },
    });
    const searchHints = reactive({
      display: false,
      rows: [] as string[],
    });
    const delayedGetSuggestions = debounce(async () => {
      let request: any = currentData.address;
      request = request.toLowerCase().includes('киргизия') ? request : `киргизия ${request}`;
      const obj: any = await (window.ymaps as any).suggest(request);
      searchHints.rows = obj.map((item: any) => ({ name: item.displayName, value: item.value }));
    }, 500);

    watch(() => currentData.address, () => {
      delayedGetSuggestions();
    });

    watch(() => props.data, () => {
      Object.assign(currentData, props.data);
    }, { deep: true, immediate: true });

    return {
      modalMallState,
      currentData,
      searchHints,
    };
  },
});
