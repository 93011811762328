
import { defineComponent, reactive, watch } from 'vue';
import { Mall, MallTaxpayer } from '@/types/Markets';
import api from '@/api';
import usePagination from '@/modules/usePagination';
import { useRoute, useRouter } from 'vue-router';
import ViewportObserver from '@/components/ViewportObserver.vue';
import ModalMallEdit from '@/views/markets-and-malls/components/ModalMallEdit.vue';
import { useStore } from 'vuex';

interface CurrentFilters extends Record<string, unknown>{
  currentId: number | null,
  floor: string | null,
}

export default defineComponent({
  name: 'index',
  components: { ViewportObserver, ModalMallEdit },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const taxpayers = usePagination({
      rows: [] as MallTaxpayer[],
      filters: {
        currentId: null,
        floor: null,
      } as CurrentFilters,
      immediateFilters: ['floor'],
      delayedFilters: [],
      async fetchContents() {
        const filters = taxpayers.filters as CurrentFilters;
        const id = Number(route.params.mallId);
        const { floor } = filters;
        return (await api.malls.getTaxpayers(id, { ...taxpayers.pagination.params, floor })).data;
      },
    });

    const mallInfo = reactive({
      data: null as Mall | null,
      fetching: false,
      floorOptions: [] as {name: string; value: string | null}[],
      async fetch() {
        mallInfo.fetching = true;
        try {
          // eslint-disable-next-line no-use-before-define
          const id = Number(route.params.mallId);
          if (!id) {
            mallInfo.data = null;
          } else {
            mallInfo.data = (await api.malls.getById(id)).data;
            mallInfo.floorOptions = [
              { name: 'Не выбрано', value: null },
              ...mallInfo.data.floorsName.map((item) => ({
                name: item,
                value: item,
              })),
            ];
          }
        } catch (e: unknown) {
          console.log(e);
        } finally {
          mallInfo.fetching = false;
        }
      },
    });

    const modalMall = reactive({
      displayMallEdit: false,
      async deleteMall() {
        const confirmDelete = window.confirm('Вы уверены?');
        if (mallInfo?.data?.id && confirmDelete) {
          try {
            await api.malls.deleteMall(mallInfo.data.id);
            await router.push({ name: 'markets-and-malls-malls' });
          } catch (e: unknown) {
            console.error(e);
            alert((e as Record<string, unknown>)?.message || 'Неизвестная ошибка на сервере');
          }
        }
      },
    });

    function filterFromQuery() {
      const { floor } = route.query;
      const { mallId } = route.params;
      const filters = { floor: floor || null, currentId: mallId || null };
      Object.assign(taxpayers.filters, filters);
    }

    function filterToQuery(filters: {floor?: number | null, currentId?: number | null}) {
      // добавляет в query переданные значения
      // удаляет из query значения, которые переданы как null | void
      // const oldQuery = route.query;
      Object.keys(filters).forEach((key) => {
        delete route.query[key];
      });
      router.replace({ query: { ...route.query, ...filters } });
    }

    taxpayers.fetch();
    mallInfo.fetch();

    const handleCloseMallModal = () => {
      modalMall.displayMallEdit = false;
      mallInfo.fetch();
      taxpayers.fetch();
    };

    watch(
      () => [route.query],
      filterFromQuery,
      { immediate: true },
    );

    const statusColor = (status: string) => {
      switch (status) {
        case 'LIQUIDATED':
          return '#000';
        case 'NOTPROCESSED':
          return '#ff9d7e';
        // case 'DUPLICATE':
        //   return '#FF0000';
        default:
          return '#7eb9ff';
      }
    };
    const isRoleCA = store.state.auth.isCA;

    return {
      mallInfo,
      taxpayers,
      filterToQuery,
      statusColor,
      modalMall,
      handleCloseMallModal,
      isRoleCA,
    };
  },
});
